import React from "react";
import { noop, get } from "lodash";
import Button from "../Button";
import { TextInput } from "../Inputs";
import { getTableCodeValidator } from "../../utils/table-codes";
import TableIcon from "../icons/Table.svg";

import styles from "./index.module.scss";

export default class TableCode extends React.Component {
  state = { error: null };

  onClick = () => {
    const {
      T,
      branchAvailability,
      onSetTableCode = noop,
      preSetTableCode = noop,
    } = this.props;
    const { branch = {}, tableCodeRanges } = branchAvailability;

    const validateCodeInput = new Promise((resolve) =>
      this.textInput.validate((error, value) => resolve({ error, value })),
    );
    validateCodeInput.then(({ error, value }) => {
      if (error) {
        this.setState({ error });
      } else {
        preSetTableCode(value);
        this.props.setTableCode(value);
        this.props.onClose();
        onSetTableCode(value);
      }
    });
  };

  render() {
    const { T, appStyles, branchAvailability = {}, tableCode } = this.props;
    const { branch = {} } = branchAvailability;
    const isValidTableCode = getTableCodeValidator(branchAvailability);

    return (
      <React.Fragment>
        <TableIcon
          className={styles.TableIcon}
          style={{ color: appStyles.accentColor }}
        />
        <h2 style={{ textAlign: "center", color: appStyles.accentColor }}>
          {branch.name}
        </h2>
        <div
          className={styles.TableCodeWrapper}
          style={{ color: get(appStyles, "Card.color") }}
        >
          <span style={{ ...appStyles.CardTitle, fontSize: "1.5rem" }}>
            {T("Ordering requires a code")}
          </span>
          <span style={{ marginBottom: 16, marginTop: 6 }}>
            {T("Please enter the code from your table to start ordering")}
          </span>
          <TextInput
            appStyles={appStyles}
            placeholder={T("Enter Table Code")}
            refEl={(el) => (this.textInput = el)}
            validator={isValidTableCode}
            errorMessage={T(
              "Table No. not available, please consult the staff",
            )}
            type="tel"
          />
        </div>
        <Button
          appStyles={appStyles}
          centered
          style={{ marginTop: 16 }}
          onClick={this.onClick}
        >
          {T("Order Now")}
        </Button>
      </React.Fragment>
    );
  }
}
