import React from 'react';
import Button from '../Button';
import { setParams, navigateTo } from '../../utils/location';
import EmptyBagIcon from '../icons/EmptyStateBag.svg';
import AvailabilityPanel from './AvailabilityPanel';
import styles from './index.module.scss';

export default ({ T, appStyles, branchAvailability = {}, servingOptionTag, params }) => {
  const { branch = {}, branchId, availableFrom = null } = branchAvailability;
  
  return (
    <React.Fragment>
      <EmptyBagIcon className={styles.EmptyBagIcon} style={{ color: appStyles.accentColor }} />
      <h2 style={{ textAlign: 'center', color: appStyles.accentColor }}>{branch.name}</h2>
      <AvailabilityPanel
        T={T}
        appStyles={appStyles}
        disableOrder={true}
        availableFrom={availableFrom}
        timeZoneStr={branch.timeZoneStr}
        servingOptionTag={servingOptionTag}
      />
      <Button
        appStyles={appStyles}
        centered
        style={{ marginTop: 16 }}
        to={
            setParams('/order', {
              ...params,
              branchId,
              branchName: branch.name,
            })
          
        }
      >
        {T('See the menu anyway')}
      </Button>
    </React.Fragment>
  );
};
