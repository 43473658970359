import localeDates from "./localeDates";
import moment from "./moment-timezone-with-data-2012-2022";
import capitalize from "lodash/capitalize";
import { SERVING_OPTION } from "./constants";

export default ({
  T,
  availableFrom,
  timeZoneStr,
  locale,
  servingOptionTag,
  servingOptionType,
  closedMessage,
}) => {
  const isAvailableToday =
    moment.tz(timeZoneStr).dayOfYear() ===
    moment.tz(availableFrom, timeZoneStr).dayOfYear();
  const isAvailableTomorrow =
    moment.tz(timeZoneStr).dayOfYear() ===
    moment.tz(availableFrom, timeZoneStr).dayOfYear() - 1;
  const isCatering =
    servingOptionType === SERVING_OPTION.CATERING ||
    servingOptionType === SERVING_OPTION.CATERING_DELIVERY;
  const amountOfDaysToOrderAheadCatering =
    moment.tz(availableFrom, timeZoneStr).dayOfYear() -
    moment.tz(timeZoneStr).dayOfYear();
  const cateringText = T(
    `${T(
      `${T(servingOptionTag)} must be ordered at least`,
    )} ${amountOfDaysToOrderAheadCatering} ${
      amountOfDaysToOrderAheadCatering > 1 ? "days" : "day"
    } ${T("in advance")}`,
  );

  const textPrefix = capitalize(
    availableFrom
      ? isAvailableToday
        ? `${T(`${servingOptionTag} ${T("is closed, starts today at")}`)}`
        : isAvailableTomorrow
        ? `${T(
            `${servingOptionTag} ${T("is closed today, starts tomorrow at")}`,
          )}`
        : servingOptionTag
      : servingOptionTag,
  );

  const closedText = availableFrom
    ? isCatering && amountOfDaysToOrderAheadCatering > 0
      ? cateringText
      : isAvailableToday || isAvailableTomorrow
      ? `${textPrefix} ${moment
          .tz(availableFrom, timeZoneStr)
          .format(localeDates[locale || "en-US"].hourFormat)}`
      : `${T(`${textPrefix} ${T("Closed")}`)}`
    : `${T(`${textPrefix} ${T("Closed")}`)}`;

  return closedMessage ? closedMessage : closedText;
};
