import React from "react";
import _ from "lodash";
import { getDistanceToBranch } from "../../utils/geoLocation";
import { setParams } from "../../utils/location";
import AppContainer from "../AppContainer";
import Loader from "../Loader";
import Button from "../Button";
import List from "../List";
import BranchListItem from "./BranchListItem";
import ErrorMessage from "../ErrorMessage";
import DeliveryBigIcon from "../icons/DeliveryBig.svg";
import DropOffIcon from "../icons/DropOff.svg";
import moment from "../../utils/moment-timezone-with-data-2012-2022";
import * as CONSTANTS from "../../utils/constants";
import styles from "./index.module.scss";
import debounceRender from "react-debounce-render";

class BranchesList extends React.Component {
  setDistanceToBranch = (branch) => {
    if (!this.props.userCoords || !this.props.google) {
      return { ...branch, distanceToBranch: null };
    }

    const point = {
      lat: () => Number(this.props.userCoords.latitude),
      lng: () => Number(this.props.userCoords.longitude),
    };

    return {
      ...branch,
      distanceToBranch: getDistanceToBranch(
        {
          point,
          branch,
        },
        this.props.google,
      ),
    };
  };

  shouldShowOrderLater = (branchAvailability) => {
    const {
      servingOption: { enableFutureOrders, offersPickupTime },
    } = this.props;
    const { branch, availability, availableFrom, closedMessage } =
      branchAvailability || {};
    const isAvailableLater = availability === CONSTANTS.AVAILABLE_LATER;
    const branchEnableFutureOrders =
      enableFutureOrders && !branch.disableFutureOrders;
    const isOpenLaterToday =
      availableFrom &&
      moment.tz(availableFrom, branch.timeZoneStr).day() ===
        moment.tz(branch.timeZoneStr).day();
    return (
      closedMessage ||
      (isAvailableLater &&
        (branchEnableFutureOrders ||
          (!branchEnableFutureOrders && isOpenLaterToday && offersPickupTime)))
    );
  };

  shouldDisableOrder = (branchAvailability) => {
    const {
      servingOption: { enableFutureOrders, offersPickupTime },
    } = this.props;
    const { availability, availableFrom, branch } = branchAvailability;
    const branchIsUnavailable =
      availability === CONSTANTS.TEMPORARILY_UNAVAILABLE;
    const branchIsAvailableLater = availability === CONSTANTS.AVAILABLE_LATER;
    const branchDisableFutureOrders =
      !enableFutureOrders || branch.disableFutureOrders;
    const isNotOpenToday =
      availableFrom &&
      moment.tz(availableFrom, branch.timeZoneStr).day() !==
        moment.tz(branch.timeZoneStr).day();

    return (
      branchIsUnavailable ||
      (branchDisableFutureOrders &&
        (isNotOpenToday ||
          (!isNotOpenToday && !offersPickupTime && branchIsAvailableLater)))
    );
  };

  render() {
    const {
      appStyles = {},
      locations,
      T,
      params,
      branches,
      branchesFilter,
      userCoords,
      customTarget,
      reloadBranchAvailabilty = _.noop,
      currencySymbol,
      metric,
      onBranchLocationClick,
      branchesAvailability,
      deliveryOptions,
      servingOption: {
        needsAddress,
        type,
        servingOptionTag,
        forSearchOnlyByCode,
        hasTableCodes,
      },
      userEnteredInvalidHouseNumber,
      loadingUserAddress,
    } = this.props;

    const isDelivery = type === "delivery";
    const noCoordsEntered =
      needsAddress &&
      (!userCoords || (userCoords && userEnteredInvalidHouseNumber));

    if (noCoordsEntered) {
      return isDelivery && !userEnteredInvalidHouseNumber ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            marginTop: "25%",
          }}
          className={styles.BigDeliveryIcon}
        >
          <DeliveryBigIcon />
        </div>
      ) : (
        <div />
      );
    }
    const {
      loadingBranchesAvailability,
      loadBranchesAvailabilityError,
      loadingDeliveryOptions,
    } = locations;

    const { accentColor, actionColor, cardBackgroundColor, rtl } = appStyles;

    const availableBranchIds = _.keys(branchesAvailability);

    // filter for gifts etc.:
    const filteredBranches = _.isEmpty(branchesFilter)
      ? _.filter(branches, (branch) => !branch.searchCode)
      : _.filter(branches, (branch) => _.includes(branchesFilter, branch.id));

    if (forSearchOnlyByCode && _.isEmpty(branchesFilter)) {
      return (
        <div className={styles.DropOffIcon}>
          <DropOffIcon />
        </div>
      );
    }

    // range filter
    const availableBranchesInRange = _.sortBy(
      _.filter(
        _.map(
          _.filter(filteredBranches, (branch) =>
            _.includes(availableBranchIds, branch.id),
          ),
          this.setDistanceToBranch,
        ),
        (branch) => {
          return branch.distanceToBranch && branch.radiusInMeters !== 10000000
            ? branch.distanceToBranch <= branch.radiusInMeters
            : true;
        },
      ),
      "distanceToBranch",
    );

    const deliveryOptionsFilteredByBranchesAvailability = _.filter(
      _.filter(deliveryOptions, (option) =>
        _.find(
          branchesAvailability,
          (branchAvailability) =>
            branchAvailability.branchId === option.branchId,
        ),
      ),
      "branch.isDisplayed",
    );

    if (
      locations.loadingBranchesAvailability ||
      loadingDeliveryOptions ||
      loadingUserAddress
    ) {
      return (
        <AppContainer.CenteredColumn
          style={{ height: "20em", justifyContent: "center" }}
        >
          <Loader color={accentColor} />
          <strong>{T("Checking locations availability...")}</strong>
        </AppContainer.CenteredColumn>
      );
    }

    const paramsToKeep = _.pick(
      params,
      "servingOptionType",
      "toCategoryId",
      "toCategoryName",
      "checkdeal",
      "checkitem",
    );

    if (needsAddress) {
      return _.isEmpty(deliveryOptionsFilteredByBranchesAvailability) ? (
        <ErrorMessage appStyles={appStyles}>
          {T("We don't have locations available in your area")}
        </ErrorMessage>
      ) : (
        <List rtl={rtl}>
          {_.map(
            deliveryOptionsFilteredByBranchesAvailability,
            (deliveryOption, index) => {
              const branch = _.find(branches, { id: deliveryOption.branch.id });
              const timeZoneStr = branch ? branch.timeZoneStr : null;
              const disableOrder = this.shouldDisableOrder(
                branchesAvailability[branch.id],
              );

              return (
                <BranchListItem
                  appStyles={appStyles}
                  card
                  T={T}
                  index={index}
                  key={deliveryOption.branch.id}
                  branch={{ ...deliveryOption.branch, timeZoneStr }}
                  actionColor={actionColor}
                  backgroundColor={cardBackgroundColor}
                  showOrderLater={this.shouldShowOrderLater(
                    branchesAvailability[deliveryOption.branch.id],
                  )}
                  disableOrder={disableOrder}
                  availableFrom={_.get(
                    branchesAvailability[deliveryOption.branch.id],
                    "availableFrom",
                  )}
                  showAddress
                  onClick={this.props.onSelectedBranch(
                    deliveryOption.branch.id,
                    disableOrder,
                  )}
                  to={setParams(customTarget || "/order", {
                    branchId: deliveryOption.branch.id,
                    branchName: deliveryOption.branch.name,
                    ...paramsToKeep,
                  })}
                  minimumOrderAmount={deliveryOption.minimumOrderAmount}
                  currencySymbol={currencySymbol}
                  metric={metric}
                  onBranchLocationClick={onBranchLocationClick}
                  servingOptionTag={servingOptionTag}
                  servingOptionType={type}
                  closedMessage={_.get(
                    branchesAvailability[branch.id],
                    "closedMessage",
                  )}
                />
              );
            },
          )}
        </List>
      );
    }
    if (loadBranchesAvailabilityError)
      return (
        <ErrorMessage appStyles={appStyles}>
          {T("We had a connection problem")}
          <Button
            slim
            centered
            appStyles={appStyles}
            onClick={reloadBranchAvailabilty}
          >
            {T("Try Again")}
          </Button>
        </ErrorMessage>
      );
    return _.isEmpty(availableBranchesInRange) ? (
      <ErrorMessage appStyles={appStyles}>
        {T("We don't have locations available in your area")}
      </ErrorMessage>
    ) : (
      <List rtl={rtl}>
        {_.map(availableBranchesInRange, (branch, index) => {
          const disableOrder = this.shouldDisableOrder(
            branchesAvailability[branch.id],
          );

          const hasTableCode =
            hasTableCodes &&
            !_.isEmpty(
              _.get(branchesAvailability, `[${branch.id}].tableCodeRanges`),
            );
          return (
            <BranchListItem
              appStyles={appStyles}
              T={T}
              index={index}
              key={branch.id}
              branch={branch}
              actionColor={actionColor}
              backgroundColor={cardBackgroundColor}
              showOrderLater={this.shouldShowOrderLater(
                branchesAvailability[branch.id],
              )}
              disableOrder={disableOrder}
              distanceToBranch={branch.distanceToBranch || 1.0}
              availableFrom={_.get(
                branchesAvailability[branch.id],
                "availableFrom",
              )}
              showAddress
              onClick={this.props.onSelectedBranch(branch.id, disableOrder)}
              to={setParams(customTarget || "/order", {
                branchId: branch.id,
                branchName: branch.name,
                ...paramsToKeep,
              })}
              metric={metric}
              onBranchLocationClick={onBranchLocationClick}
              servingOptionTag={servingOptionTag}
              servingOptionType={type}
              hasTableCode={hasTableCode}
              closedMessage={_.get(
                branchesAvailability[branch.id],
                "closedMessage",
              )}
            />
          );
        })}
      </List>
    );
  }
}

export default debounceRender(BranchesList, 500, {
  maxWait: 500,
});
